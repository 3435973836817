import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import { createGraphQLPromise } from "../../common/graphql";
import { GQL_FRAGMENT_GEO_TARGETING } from "../gql-geotargeting";

const GQL_MESSAGE_FRAGMENT = gql`
    fragment message on Message {
        title
        state
        commercialState
        completedEndDate
        slug
        lang
        id
        currency
        topic
        texts {
            id
            type
            value
            role
        }
        startDate
        liveEndDate
        createdDate
        images {
            id
            imageUrl
            height
            width
            type
            role
        }
        movies {
            id
            amplifyVideoUrl
            videoUrl
            duration
            size
            height
            width
            image {
                id
                imageUrl
                height
                width
                type
            }
            type
            role
        }
        owner {
            id
            avatarUrl
            email
            firstName
            lastName
        }
        stats {
            totalAmount
            totalSpent
            perfIndex
            lastSpentAt
        }
        landing {
            slug
        }
        perfs {
            index
        }
        availableWallet {
            balance
        }
        isAutoPlatform
        platformsInfo {
            name
            uniqueClickCount
            reach
            perfIndex
            budget
            spent
            state
            fullState
            syncError
            invalidReason
            lastSpentAt
            adManagerUrl
            adAdminUrl
            cpcMax
        }
        business {
            id
            uuid
        }
        platformsMessage {
            platform
            active
            budgetAllocationFactor
            format
        }
        wizardMetas
        initialAmount
        initialInterval
        msgSubscription {
            gateway
        }
        subscription {
            adAmount
            amount
            currency
            startedAt
            nextDueDate
        }
        agency {
            name
        }
    }
`;

const GQL_MESSAGE_METRICS = gql`
    fragment messageMetrics on Metric {
        date
        platform
        goal
        impressions
        clickCount
        uniqueClickCount
        reach
        adBudget
        spent
        conversion
    }
`;

const GQL_MESSAGE_LOGS = gql`
    fragment messagelogs on MessageLog {
        id
        insertedAt
        insertedBy {
            id
            email
            avatarUrl
        }
        text
        level
        platform
    }
`;

const GQL_MESSAGE_DETAILS_FRAGMENT = gql`
    fragment messageDetails on Message {
        lastReview {
            id
            state
            isNew
            changes {
                key
                old
                new
            }
        }
        targetingLinks {
            ...targetingLinks
        }
        targetingMaxAge
        targetingMinAge
        targetingGender
        targetingProfile {
            id
            title
        }
        adLink
        utm
        completedReason
        completedEndDate
    }
    ${GQL_FRAGMENT_GEO_TARGETING}
`;

export const GQL_LANDINGS_PAGES = gql`
    query getMyLandings($offset: Int!, $limit: Int!, $q: String) {
        getMyLandings(offset: $offset, limit: $limit, q: $q) {
            count
            nextOffset
            results {
                slug
                userId
                username
            }
        }
    }
`;

export const GQL_ALL_MESSAGES_QUERY = gql`
    query allMessages(
        $limit: Int
        $offset: Int
        $id: UuidTextFilter
        $title: StringTextFilter
        $wizardMetas: StringJsonFilter
        $owner: StringTextFilter
        $ownerSubscriptionProduct: StringSubscriptionproductFilter
        $topic: TopicInFilter
        $state: MessagestateInFilter
        $perf: FloatCompareFilter
        $lang: StringInFilter
        $platform: PlatformvalueInFilter
        $platformSyncError: StringTextFilter
        $platformState: MessageadstateInFilter
        $notDelivering: NotdeliveringFilter
        $orderBy: String
        $initialInterval: StringInFilter
    ) {
        allMessages(
            limit: $limit
            offset: $offset
            id: $id
            title: $title
            wizardMetas: $wizardMetas
            owner: $owner
            ownerSubscriptionProduct: $ownerSubscriptionProduct
            topic: $topic
            state: $state
            perfIndex: $perf
            lang: $lang
            platform: $platform
            platformSyncError: $platformSyncError
            platformState: $platformState
            notDelivering: $notDelivering
            orderBy: $orderBy
            initialInterval: $initialInterval
        ) {
            count
            results {
                ...message
            }
        }
    }

    ${GQL_MESSAGE_FRAGMENT}
`;

export const GQL_UPDATE_MESSAGE_MUTATION = gql`
    mutation UpdateMessage($id: UUID!, $changes: MessageChanges!) {
        updateMessage(id: $id, changes: $changes) {
            message {
                ...message
                ...messageDetails
            }
        }
    }

    ${GQL_MESSAGE_FRAGMENT}
    ${GQL_MESSAGE_DETAILS_FRAGMENT}
`;

export const GQL_CREATE_MESSAGE_MUTATION = gql`
    mutation CreateMessage($message: MessageCreate!) {
        createMessage(message: $message) {
            message {
                id
            }
        }
    }
`;

export const GQL_DELETE_MESSAGE_MUTATION = gql`
    mutation DeleteMessage($id: UUID!) {
        deleteMessage(id: $id) {
            ok
        }
    }
`;

export const GQL_CREATE_AI_DRAFT = gql`
    mutation CreateAiDraft($messageId: String!, $lang: String!) {
        createAiDraft(messageId: $messageId, lang: $lang) {
            ok
        }
    }
`;

export const GQL_QUERY_MESSAGE = gql`
    query getMessage($id: UUID!) {
        getMessage(id: $id) {
            ...message
            ...messageDetails
        }
    }
    ${GQL_MESSAGE_FRAGMENT}
    ${GQL_MESSAGE_DETAILS_FRAGMENT}
`;

export const GQL_QUERY_CHECK_MESSAGE_ASSETS = gql`
    query checkMessageAssets($id: UUID!) {
        checkMessageAssets(id: $id) {
            ...message
            ...messageDetails
        }
    }
    ${GQL_MESSAGE_FRAGMENT}
    ${GQL_MESSAGE_DETAILS_FRAGMENT}
`;

export const GQL_QUERY_MESSAGE_METRICS = gql`
    query getMessageMetrics($messageId: UUID!, $startDate: String, $endDate: String) {
        getMessage(id: $messageId) {
            id
            currency
            title
            imageUrl
            metrics(startDate: $startDate, endDate: $endDate) {
                ...messageMetrics
            }
            revisions(state: "ready") {
                state
                id
                lastStateChangedAt
                changes {
                    key
                }
            }
            backers {
                amount
                insertedAt
            }
        }
    }
    ${GQL_MESSAGE_METRICS}
`;

export const GQL_QUERY_MESSAGE_LOGS = gql`
    query getMessageMetrics($messageId: UUID!) {
        getMessage(id: $messageId) {
            id
            currency
            title
            logs {
                ...messagelogs
            }
            ticketLogs {
                id
                insertedAt
                insertedBy {
                    id
                    email
                    avatarUrl
                }
                text
                level
                platform
            }
            revisions(state: "ready") {
                state
                id
                lastStateChangedAt
                changes {
                    key
                }
            }
            backers {
                amount
                insertedAt
                status
                paymentType
                paymentGateway
                isSubscription
            }
        }
    }
    ${GQL_MESSAGE_LOGS}
`;

export const QGL_MUTATION_PUBLISH_MESSAGE = gql`
    mutation PublishMessage($id: UUID!) {
        publishMessage(id: $id) {
            message {
                ...message
                ...messageDetails
            }
        }
    }

    ${GQL_MESSAGE_FRAGMENT}
    ${GQL_MESSAGE_DETAILS_FRAGMENT}
`;

export const QGL_MUTATION_RESTORE_MESSAGE = gql`
    mutation RestoreMessage($id: UUID!) {
        restoreMessage(id: $id) {
            message {
                ...message
                ...messageDetails
            }
        }
    }

    ${GQL_MESSAGE_FRAGMENT}
    ${GQL_MESSAGE_DETAILS_FRAGMENT}
`;

export const QGL_MUTATION_STOP_MESSAGE = gql`
    mutation StopMessage($id: UUID!) {
        stopMessage(id: $id) {
            message {
                ...message
                ...messageDetails
            }
        }
    }

    ${GQL_MESSAGE_FRAGMENT}
    ${GQL_MESSAGE_DETAILS_FRAGMENT}
`;

export const QGL_MUTATION_PAUSE_MESSAGE = gql`
    mutation PauseMessage($id: UUID!) {
        pauseMessage(id: $id) {
            message {
                ...message
                ...messageDetails
            }
        }
    }
    ${GQL_MESSAGE_FRAGMENT}
    ${GQL_MESSAGE_DETAILS_FRAGMENT}
`;

export const QGL_MUTATION_RESUME_MESSAGE = gql`
    mutation ResumeMessage($id: UUID!) {
        resumeMessage(id: $id) {
            message {
                ...message
                ...messageDetails
            }
        }
    }
    ${GQL_MESSAGE_FRAGMENT}
    ${GQL_MESSAGE_DETAILS_FRAGMENT}
`;
export const QGL_MUTATION_CANCEL_PUBLISHING = gql`
    mutation CancelPublishMessage($id: UUID!) {
        cancelPublishMessage(id: $id) {
            message {
                ...message
                ...messageDetails
            }
        }
    }

    ${GQL_MESSAGE_FRAGMENT}
    ${GQL_MESSAGE_DETAILS_FRAGMENT}
`;

export const GQL_UPDATE_PLATFORM_MUTATION = gql`
    mutation UpdatePlatform($messageId: UUID!, $platform: String!, $changes: PlatformChanges!) {
        updatePlatform(messageId: $messageId, platform: $platform, changes: $changes) {
            success
        }
    }
`;

export const GQL_MUTATION_REFUND_REMAINING_AMOUNT = gql`
    mutation RefundUnusedAmountToCard($messageId: UUID!) {
        refundUnusedAmountToCard(messageId: $messageId) {
            message {
                ...message
                ...messageDetails
            }
        }
    }
    ${GQL_MESSAGE_FRAGMENT}
    ${GQL_MESSAGE_DETAILS_FRAGMENT}
`;

export const GQL_MUTATION_REFUND_WALLET_REMAINING_AMOUNT = gql`
    mutation RefundWalletToCard($userId: Int!) {
        refundWalletToCard(userId: $userId) {
            wallet {
                balance
            }
        }
    }
`;

export const useMutationStopMessage = () => {
    const [actionPromise, rest] = useMutation(QGL_MUTATION_STOP_MESSAGE, {
        refetchQueries: [GQL_ALL_MESSAGES_QUERY, GQL_QUERY_MESSAGE],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationPauseMessage = () => {
    const [actionPromise, rest] = useMutation(QGL_MUTATION_PAUSE_MESSAGE, {
        refetchQueries: [GQL_ALL_MESSAGES_QUERY, GQL_QUERY_MESSAGE],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationResumeMessage = () => {
    const [actionPromise, rest] = useMutation(QGL_MUTATION_RESUME_MESSAGE, {
        refetchQueries: [GQL_ALL_MESSAGES_QUERY, GQL_QUERY_MESSAGE],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};
export const useMutationCancelPublishing = () => {
    const [actionPromise, rest] = useMutation(QGL_MUTATION_CANCEL_PUBLISHING, {
        refetchQueries: [GQL_ALL_MESSAGES_QUERY, GQL_QUERY_MESSAGE],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationPublishMessage = () => {
    const [actionPromise, rest] = useMutation(QGL_MUTATION_PUBLISH_MESSAGE, {
        refetchQueries: [GQL_ALL_MESSAGES_QUERY, GQL_QUERY_MESSAGE],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationRestoreMessage = () => {
    const [actionPromise, rest] = useMutation(QGL_MUTATION_RESTORE_MESSAGE, {
        refetchQueries: [GQL_ALL_MESSAGES_QUERY, GQL_QUERY_MESSAGE],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationUpdateMessage = () => {
    const [actionPromise, rest] = useMutation(GQL_UPDATE_MESSAGE_MUTATION, {
        refetchQueries: [GQL_ALL_MESSAGES_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationCreateMessage = () => {
    const [actionPromise, rest] = useMutation(GQL_CREATE_MESSAGE_MUTATION, {
        refetchQueries: [GQL_ALL_MESSAGES_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationDeleteMessage = () => {
    const [actionPromise, rest] = useMutation(GQL_DELETE_MESSAGE_MUTATION, {
        refetchQueries: [GQL_ALL_MESSAGES_QUERY],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useCreateAiDraft = () => {
    const [actionPromise, rest] = useMutation(GQL_CREATE_AI_DRAFT);
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationRefundRemainingAmount = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_REFUND_REMAINING_AMOUNT, {
        refetchQueries: [GQL_ALL_MESSAGES_QUERY, GQL_QUERY_MESSAGE],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useMutationRefundWalletToCard = () => {
    const [actionPromise, rest] = useMutation(GQL_MUTATION_REFUND_WALLET_REMAINING_AMOUNT);
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};

export const useQueryMessage = (id) => {
    const { data: { getMessage } = {}, ...rest } = useQuery(GQL_QUERY_MESSAGE, {
        variables: { id },
        fetchPolicy: "no-cache",
    });
    return { message: getMessage, ...rest };
};
export const useQueryLandings = (offset, limit, q) => {
    const { data: { getMyLandings: landings } = {}, ...rest } = useQuery(GQL_LANDINGS_PAGES, {
        variables: { offset, limit, q },
    });
    return { landings, ...rest };
};
export const useQueryMessageMetrics = (messageId, startDate, endDate) => {
    const { data: { getMessage: message } = {}, ...rest } = useQuery(GQL_QUERY_MESSAGE_METRICS, {
        variables: { messageId, startDate, endDate },
    });
    return { message, ...rest };
};

export const checkMessageAssetsPromise = createGraphQLPromise({
    query: `
       query checkMessageAssets($id: UUID!) {
        checkMessageAssets(id: $id) {
            valid
            errors
        }
    }
    `,
    resolveSuccess: (data) => data.checkMessageAssets || [],
});

export const useQueryMessageLogs = (messageId) => {
    const { data: { getMessage: message } = {}, ...rest } = useQuery(GQL_QUERY_MESSAGE_LOGS, {
        variables: { messageId },
    });
    return { message, ...rest };
};

export const useMutationUpdatePlatform = () => {
    const [actionPromise, rest] = useMutation(GQL_UPDATE_PLATFORM_MUTATION, {
        refetchQueries: [GQL_ALL_MESSAGES_QUERY, GQL_QUERY_MESSAGE],
    });
    return [useCallback((variables) => actionPromise({ variables }), [actionPromise]), rest];
};
